<template>
  <div class="project-builder-workflow project-contract">
    <ConfirmDialog ref="confirmDialog" />

    <CCard class="project-builder-workflow__card">
      <CCardBody class="project-builder-workflow__card-body">
        <CCol class="col-12 col-lg-12 col-xl-8">
          <BuilderSteps class="project-builder-workflow__steps" :step="step" />
        </CCol>

        <div v-if="loadingPage" class="project-builder-workflow__card-loading">
          <CSpinner color="primary" />
        </div>

        <template v-else>
          <CNInput
            v-model="form.projectName"
            :disabled="disabled"
            label="Project Name"
            class="project-builder-workflow__project-name"
            required
            variant="ghost"
          />

          <div class="project-builder-workflow__content">
            <h3 class="project-builder-workflow__title">Contract Workflow</h3>

            <p class="project-builder-workflow__paragraph">
              The workflows to draft and approve the scope of work can be
              updated in the
              <router-link
                v-if="canToSettings"
                :to="{ name: 'SettingContractApprovals' }"
              >
                Settings
              </router-link>
              <router-link v-else to="" @click="showError()">
                Settings </router-link
              >.
            </p>

            <div
              class="project-builder-workflow__workflow"
              :class="{
                'project-builder-workflow__workflow--no-options':
                  !contractsApproval.length,
              }"
            >
              <CNSelect
                v-model="form.contractId"
                class="project-builder-workflow__select"
                :options="contractsApproval"
                :loading="loadingContractsApproval"
                :disabled="loadingContractsApproval || disabled"
                label="Select Contract Workflow"
                required
              >
                <template #nooptions>
                  <EmptyOptionsList
                    title="Set workflows"
                    description="There is no approval workflow currently available yet.
                    <br />
                    One needs to be set-up before any projects can be created.
                    <br />
                    Let us help you with this task!"
                    :button="{
                      text: 'Add workflow',
                      route: () =>
                        $router.push({ name: 'SettingContractApprovals' }),
                    }"
                  />
                </template>
              </CNSelect>
            </div>

            <div
              class="project-builder-workflow__sections"
              :class="[
                !form.contractId &&
                  'project-builder-workflow__sections--disabled',
              ]"
            >
              <div class="project-builder-workflow__sections-item">
                <h4 class="project-builder-workflow__heading">
                  People negotiating the redlines
                </h4>

                <p
                  v-if="form.includesJobsNegotiating"
                  class="project-builder-workflow__description"
                >
                  It includes: {{ form.includesJobsNegotiating }}
                </p>

                <DynamicDropdownsList
                  :options="optionsNegotiating"
                  :items="form.fieldsNegotiating"
                  :can-delete="!disabled"
                  :disabled="
                    disabledFieldsNegotiating || !form.contractId || disabled
                  "
                  @change="changePeopleWorkflow($event, 'fieldsNegotiating')"
                />
              </div>

              <div class="project-builder-workflow__sections-item">
                <h4 class="project-builder-workflow__heading">
                  Person signing the final contracts
                </h4>

                <p
                  v-if="form.includesJobsSigning"
                  class="project-builder-workflow__description"
                >
                  It includes: {{ form.includesJobsSigning }}
                </p>

                <DynamicDropdownsList
                  :options="optionsSigning"
                  :items="form.fieldsSigning"
                  :can-delete="!disabled"
                  :disabled="
                    disabledFieldsSigning || !form.contractId || disabled
                  "
                  without-additional-fields
                  @change="changePeopleWorkflow($event, 'fieldsSigning')"
                />
              </div>
            </div>
          </div>

          <FixedBottomPanel
            :buttons="fixedBottomPanelButtons"
            @deleteHandler="deleteProjectHandler"
          />
        </template>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import workflowMixin from '../workflowMixin'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'
import $p from '@/utils/constants/permissions'

export default {
  name: 'Index',
  mixins: [workflowMixin],
  async beforeRouteLeave() {
    if (this.isEditForm) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            return true
          } else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  data() {
    return {
      form: {
        projectName: '',
        contractId: '',
        fieldsNegotiating: [],
        fieldsSigning: [],
        includesJobsNegotiating: '',
        includesJobsSigning: '',
      },

      contractsApproval: [],

      optionsNegotiating: [],
      optionsSigning: [],

      loadingContractsApproval: false,

      disabledFieldsNegotiating: false,
      disabledFieldsSigning: false,

      step: 3,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
      user: 'user',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
    canToSettings() {
      return this.user.can($p.ACCOUNT_CONFIGURATION_VIEW)
    },
    disabledSavingButtons() {
      return (
        !this.form.fieldsSigning?.length || !this.form.fieldsNegotiating?.length
      )
    },
    fixedBottomPanelButtons() {
      return [
        {
          text: 'Cancel',
          disabled: this.loading || !this.isEditForm,
          outline: true,
          event: () => this.handleCancel(),
        },
        {
          text: 'Save',
          disabled: this.loading || this.disabledSavingButtons,
          outline: true,
          event: () => this.save({ workflowKey: 'contract' }),
        },
        {
          text: 'Save & next',
          disabled: this.loading || this.disabledSavingButtons,
          event: () => this.save({ redirect: true, workflowKey: 'contract' }),
        },
      ]
    },
  },
  watch: {
    'form.contractId'(id) {
      if (!id) return
      this.fetchContractWorkflowFields(id)
    },
    'form.fieldsNegotiating'(fields) {
      this.setOptionsWithExcludedIds(fields, 'Negotiating')
    },
    'form.fieldsSigning'(fields) {
      this.setOptionsWithExcludedIds(fields, 'Signing')
    },
  },
  methods: {
    showError() {
      this.$notify(permissionError)
    },
    fetchData() {
      const requests = [
        this.fetchProjectData(),
        this.fetchContactsApprovalWorkflowList(),
      ]

      return Promise.all(requests)
    },
    fetchProjectData() {
      this.loading = true

      return this.$http.projects
        .getProjectInfo(this.$route.params.id)
        .then(({ data: { data } }) => {
          this.form.projectName = data.name
          this.form.contractId = data.contract_approval_workflow_id

          this.origin = this.$deepClone(this.form)

          this.step = data.step
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchContactsApprovalWorkflowList() {
      this.loadingContractsApproval = true
      this.loading = true

      return this.$http.setting
        .contractsApprovalWorkflowList({
          params: {
            per_page: -1,
          },
        })
        .then(({ data: { data } }) => {
          this.contractsApproval = data.map((scope) => ({
            value: scope.id,
            name: scope.name,
          }))
        })
        .finally(() => {
          this.loading = false
          this.loadingContractsApproval = false
        })
    },
    fetchContractWorkflowFields(workflowId) {
      this.loading = true

      this.$http.projects
        .getContractWorkflowFields({
          params: {
            project_id: this.$route.params.id,
            workflow_id: workflowId,
          },
        })
        .then(({ data: { data } }) => {
          const fieldsNegotiating = data.document_approvals
          const fieldsSigning = data.signature_approvals

          this.form.includesJobsNegotiating = data.document_approvals_includes
          this.form.includesJobsSigning = data.signature_approvals_includes

          this.form.fieldsNegotiating =
            this.dataAdapterWorkflowFields(fieldsNegotiating)
          this.form.fieldsSigning =
            this.dataAdapterWorkflowFields(fieldsSigning)

          if (
            this.origin.contractId === workflowId &&
            this.form.fieldsNegotiating !== this.origin.fieldsNegotiating &&
            this.form.fieldsSigning !== this.origin.fieldsSigning
          ) {
            this.origin = this.$deepClone(this.form)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '../style';
</style>
